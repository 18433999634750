/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { styled, t } from '@superset-ui/core';
import { Form, FormItem } from 'src/components/Form';
import Select from 'src/components/Select/Select';
import { Col, Row } from 'src/components';
import Button from 'src/components/Button';
import {
  AggregationFn,
  ColumnAggregationRule,
} from '@superset-ui/chart-controls';

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const aggregationOptions = [
  { value: AggregationFn.SUM, label: AggregationFn.SUM },
  { value: AggregationFn.MIN, label: AggregationFn.MIN },
  { value: AggregationFn.MAX, label: AggregationFn.MAX },
  { value: AggregationFn.EXTENT, label: AggregationFn.EXTENT },
  { value: AggregationFn.MEAN, label: AggregationFn.MEAN },
  { value: AggregationFn.MEDIAN, label: AggregationFn.MEDIAN },
  { value: AggregationFn.UNIQUE, label: AggregationFn.UNIQUE },
  { value: AggregationFn.UNIQUE_COUNT, label: AggregationFn.UNIQUE_COUNT },
  { value: AggregationFn.COUNT, label: AggregationFn.COUNT },
];

export const RuleOptionPopoverContent = ({
  rule,
  onChange,
  columns = [],
}: {
  rule?: ColumnAggregationRule;
  onChange: (rule: ColumnAggregationRule) => void;
  columns: { label: string; value: string }[];
}) => (
  <Form
    onFinish={onChange}
    initialValues={rule}
    requiredMark="optional"
    layout="vertical"
  >
    <Row gutter={12}>
      <Col span={12}>
        <FormItem
          name="column"
          label={t('Column')}
          rules={[{ required: true, message: t('Required') }]}
          initialValue={columns[0]?.value}
        >
          <Select ariaLabel={t('Select column')} options={columns} />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem
          name="func"
          label={t('Aggregation function')}
          rules={[{ required: true, message: t('Required') }]}
          initialValue={aggregationOptions[0].value}
        >
          <Select
            ariaLabel={t('Select function')}
            options={aggregationOptions}
          />
        </FormItem>
      </Col>
    </Row>
    <FormItem>
      <JustifyEnd>
        <Button htmlType="submit" buttonStyle="primary">
          {t('Apply')}
        </Button>
      </JustifyEnd>
    </FormItem>
  </Form>
);
