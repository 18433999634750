/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useEffect, useState } from 'react';
import { styled, css, t, useTheme } from '@superset-ui/core';
import Icons from 'src/components/Icons';
import ControlHeader from 'src/explore/components/ControlHeader';
import { ColumnAggregationRule } from '@superset-ui/chart-controls';
import { RuleOptionPopover } from './RuleOptionPopover';
import {
  AddControlLabel,
  CaretContainer,
  Label,
  OptionControlContainer,
} from '../OptionControls';
import { ColumnAggregationControlProps } from './types';

const RuleOptionsContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.gridUnit}px;
    border: solid 1px ${theme.colors.grayscale.light2};
    border-radius: ${theme.gridUnit}px;
  `}
`;

export const RuleOptionContainer = styled(OptionControlContainer)`
  &,
  & > div {
    margin-bottom: ${({ theme }) => theme.gridUnit}px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light1};
    height: 100%;
    width: ${theme.gridUnit * 6}px;
    border: none;
    border-right: solid 1px ${theme.colors.grayscale.dark2}0C;
    padding: 0;
    outline: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  `}
`;

const ColumnAggregationControl = ({
  value,
  onChange,
  columnOptions,
  verboseMap,
  ...props
}: ColumnAggregationControlProps) => {
  const theme = useTheme();
  const [rules, setRules] = useState<ColumnAggregationRule[]>(value ?? []);

  useEffect(() => {
    if (onChange) {
      onChange(rules);
    }
  }, [rules, onChange]);

  useEffect(() => {
    // remove formatter when corresponding column is removed from controls
    const newRules = rules.filter(rule =>
      columnOptions.some((option: any) => option?.value === rule?.column),
    );
    if (newRules.length !== rules.length) {
      setRules(newRules);
    }
  }, [rules, columnOptions]);

  const onDelete = (index: number) => {
    setRules(prevRules => prevRules.filter((_, i) => i !== index));
  };

  const onSave = (config: ColumnAggregationRule) => {
    setRules(prevRules => [...prevRules, config]);
  };

  const onEdit = (newRule: ColumnAggregationRule, index: number) => {
    const newRules = [...rules];
    newRules.splice(index, 1, newRule);
    setRules(newRules);
  };

  const createLabel = ({ column, func }: ColumnAggregationRule) => {
    const columnName = (column && verboseMap?.[column]) ?? column;
    switch (func) {
      default:
        return `${func} of ${columnName}`;
    }
  };

  return (
    <div>
      <ControlHeader {...props} />
      <RuleOptionsContainer>
        {rules.map((rule, index) => (
          <RuleOptionContainer key={index}>
            <CloseButton onClick={() => onDelete(index)}>
              <Icons.XSmall iconColor={theme.colors.grayscale.light1} />
            </CloseButton>
            <RuleOptionPopover
              title={t('Select aggregation function')}
              rule={rule}
              columns={columnOptions}
              onChange={(newRule: ColumnAggregationRule) =>
                onEdit(newRule, index)
              }
              destroyTooltipOnHide
            >
              <OptionControlContainer withCaret>
                <Label>{createLabel(rule)}</Label>
                <CaretContainer>
                  <Icons.CaretRight iconColor={theme.colors.grayscale.light1} />
                </CaretContainer>
              </OptionControlContainer>
            </RuleOptionPopover>
          </RuleOptionContainer>
        ))}
        <RuleOptionPopover
          title={t('Add new column')}
          columns={columnOptions}
          onChange={onSave}
          destroyTooltipOnHide
        >
          <AddControlLabel>
            <Icons.PlusSmall iconColor={theme.colors.grayscale.light1} />
            {t('Add new aggregation rule')}
          </AddControlLabel>
        </RuleOptionPopover>
      </RuleOptionsContainer>
    </div>
  );
};

export default ColumnAggregationControl;
